<template>
    <div
        class="container"
    >
        <div class="loading-indicator" />
    </div>
</template>

<script setup lang="ts">

</script>
<style lang="postcss" scoped>
@keyframes before {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes after {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

.container {
  @apply w-full h-1 overflow-hidden;

  .loading-indicator {
    @apply w-full h-full relative z-10;
    background-color: #b1e7b1;

    &:before, &:after {
      @apply absolute h-full z-10;
      background-color: #86d586;
      content: '';
    }

    &:before {
      animation: before 1.5s infinite ease-out;
    }

    &:after {
      animation: after 1.5s infinite ease-in;
    }
  }
}
</style>
