<script setup lang="ts">
import { TrashIcon } from '@heroicons/vue/16/solid';
import { onKeyDown } from '@vueuse/core';
import { ref, watch } from 'vue';
import { ActionButton } from '@component-library';
import DheToast from '@component-library/components/DheToast/DheToast.vue';
import LinearLoadingIndicator
    from '@component-library/components/LinearLoadingIndicator/LinearLoadingIndicator.vue';
import {
    ResponsePlanEntryWithChanges,
} from '@hq/views/Settings/ResponsePlans/ResponsePlansSettingsModel';
import type { SettingsModel } from '@hq/views/Settings/SettingsModel';

const props = defineProps<{
    model: SettingsModel;
}>();

const showError = ref(false);

watch([() => props.model.responsePlansModel.error], () => {
    showError.value = props.model.responsePlansModel.error !== null;
});

const editResponsePlanId = (rp: ResponsePlanEntryWithChanges, responsePlanId: number) => {
    props.model.responsePlansModel.editResponsePlanEntry({ ...rp, responsePlanId });
};

const editFilterGroup = (rp: ResponsePlanEntryWithChanges, filterGroup: string) => {
    props.model.responsePlansModel.editResponsePlanEntry({ ...rp, filterGroup: filterGroup.length ? filterGroup : '' });
};

const editStaff = (rp: ResponsePlanEntryWithChanges, staffJSON: string) => {
    props.model.responsePlansModel.editResponsePlanEntry({ ...rp, staff: staffJSON });
};

const editApparatus = (rp: ResponsePlanEntryWithChanges, apparatusJSON: string) => {
    props.model.responsePlansModel.editResponsePlanEntry({ ...rp, apparatus: apparatusJSON });
};

onKeyDown(['s', 'S'], (e: KeyboardEvent) => {
    if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        props.model.responsePlansModel.saveChanges();
    }
});

</script>

<template>
    <div
        class="flex flex-col w-full pt-4 bg-dhagray-50 px-8 "
    >
        <div class="flex justify-between items-center sticky left-0 mb-6">
            <h1 class="font-supermolot-demibold uppercase text-3xl self-start text-highlight-gold">
                Response Plans
            </h1>
            <div class="flex items-center gap-4">
                <ActionButton
                    data-testid="resetChangesButton"
                    icon="clear"
                    label="Reset Changes"
                    title="Reset Changes"
                    variant="light"
                    @click="() => model.responsePlansModel.resetChanges()"
                />
                <ActionButton
                    data-testid="saveButton"
                    label="Save & Apply Changes"
                    title="Save & Apply Changes (Ctrl/Cmd+S)"
                    variant="primary"
                    @click="() => model.responsePlansModel.saveChanges()"
                />
            </div>
        </div>
        <table
            class="text-left border-separate border bg-white bg-opacity-25 pt-2 px-2"
            :style="{
                borderSpacing: '4px 2px'
            }"
        >
            <tr
                v-if="model.responsePlansModel.responsePlansWithChanges.length"
                class="border-t border-r border-dhagray-100 font-supermolot-bold text-xs text-left uppercase tracking-wider text-dhagray-300"
            >
                <th class="w-32">
                    Filter Group
                </th>
                <th>Staff Requirements</th>
                <th>Apparatus Requirements</th>
                <th class="w-8">
                    Delete
                </th>
            </tr>
            <tr
                v-else
                class="font-supermolot"
            >
                <td colspan="5">
                    No response plans
                </td>
            </tr>
            <tr
                v-for="rp in model.responsePlansModel.responsePlansWithChanges"
                :key="rp.editKey"
                class="content-row"
            >
                <td
                    :class="rp.filterGroup.length === 0 ? 'text-gray-400': ''"
                >
                    <input
                        v-if="rp.changeType === 'create'"
                        placeholder="Filter Group"
                        :value="rp.filterGroup"
                        @input="(e) => editFilterGroup(rp, (e?.target as HTMLInputElement).value)"
                    >
                    <div
                        v-else
                        class="px-2"
                    >
                        {{ rp.filterGroup ?? 'None' }}
                    </div>
                </td>
                <td>
                    <textarea
                        class="w-full"
                        :value="rp.staff"
                        @input="(e) => editStaff(rp, (e?.target as HTMLTextAreaElement).value)"
                    />
                </td>
                <td>
                    <textarea
                        class="w-full"
                        :value="rp.apparatus"
                        @input="(e) => editApparatus(rp, (e?.target as HTMLTextAreaElement).value)"
                    />
                </td>
                <td>
                    <button
                        class="cursor-pointer w-6 h-6"
                        @click="() => model.responsePlansModel.deleteResponsePlanEntry(rp)"
                    >
                        <TrashIcon />
                    </button>
                </td>
            </tr>
            <tr>
                <td
                    class="border-t-[1px] py-2"
                    colspan="5"
                >
                    <ActionButton
                        data-testid="addResponsePlanEntryBottomButton"
                        icon="add"
                        label="New Response Plan"
                        @click="() => model.responsePlansModel.createResponsePlan()"
                    />
                </td>
            </tr>
        </table>
        <LinearLoadingIndicator
            v-if="model.responsePlansModel.isLoading"
            class="p-0"
        />
        <DheToast v-model="showError">
            {{ model.responsePlansModel.error }}
        </DheToast>
    </div>
</template>

<style scoped lang="postcss">
.content-row {
    & > td {
        @apply px-2;

        & > input {
            @apply px-2 border w-28 h-[34px] text-black;
        }
    }
}
</style>
