<template>
    <teleport to="body">
        <div
            class="toast-container fixed bottom-0 w-full text-center
            flex flex-row justify-center"
        >
            <div
                class="transition-transform duration-500 relative
                font-supermolot p-4 rounded-t-md bg-dhablue-500 text-white block"
                :class="isVisible
                    ? ''
                    : 'translate-y-full'"
            >
                <slot>
                    Toast {{ isVisible }}
                </slot>
            </div>
        </div>
    </teleport>
</template>

<script setup lang="ts">
import { useTimeoutFn } from '@vueuse/core';
import { ref, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    duration: {
        type: Number,
        default: 3000
    }
});
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void,
}>();

const isVisible = ref(false);

const {
    start
} = useTimeoutFn(
    () => {
        isVisible.value = false;
    },
    props.duration,
    { immediate: false }
);

watch(
    () => props.modelValue,
    (value) => {
        isVisible.value = value;
        if (value === true) {
            start();
        }
    }
);

watch(
    () => isVisible.value,
    (value) => {
        emit('update:modelValue', value);
    }
);

</script>
